@import '../../style/themes/index';
@import '../../style/mixins/index';

@backtop-prefix-cls: ~'@{ant-prefix}-back-top';

.@{backtop-prefix-cls} {
  .reset-component;

  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: @zindex-back-top;
  width: 40px;
  height: 40px;
  cursor: pointer;

  &-rtl {
    right: auto;
    left: 100px;
    direction: rtl;
  }

  &-content {
    width: 40px;
    height: 40px;
    overflow: hidden;
    color: @back-top-color;
    text-align: center;
    background-color: @back-top-bg;
    border-radius: 20px;
    transition: all 0.3s @ease-in-out;

    &:hover {
      background-color: @back-top-hover-bg;
      transition: all 0.3s @ease-in-out;
    }
  }

  &-icon {
    font-size: 24px;
    line-height: 40px;
  }
}

@import './responsive';
